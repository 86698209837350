import React from 'react';
import { graphql } from 'gatsby';


import './style.scss'

const Validator = ({ data }) => {


  return (
    <div>Page not found 404</div>)
}

export default Validator;

export const pageQuery = graphql`
  query validatorQuery {
    posts: allContentfulPage (filter: {slug: {eq: "validator"}}) {
      edges {
        node {
          id
          title 
          slug
          excerpt {
            excerpt
          }
          heroTitle
          heroCtaLink
          heroCtaText
          heroCta2Text
          heroCta2Link
          metaDescription {
            metaDescription
          }
          heroImage {
            description
            resolutions (quality: 100, width: 1000) {
              src
            }
          }
          components {
            __typename
            ... on ContentfulArticleContent {
            title
            slug
            internal {
              type
            }
          }
            __typename
            ... on ContentfulRelatedPosts {
            title
            slug
            style
            internal {
              type
            }
          }
          __typename
            ... on ContentfulPromoBlock {
            title
            slug
            internal {
              type
            }
          }
          }
        }
      }
    }
  }
`
